<template>
    <div class="pipeline-view__container citizenship-documentation-view">
        <view-header :title="'Citizenship Documentation'">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-passport"></i>
            </template>
        </view-header>
        <k-grid
            :data-items="filteredItems"
            :data-item-key="'id'"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="true"
            :filter="filter"
            :group="group"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:pipelineActions="{ props }">
                <div class="pipeline-actions__container">
                    <k-button class="upload-doc__btn" v-if="!props.dataItem.associatedDocumentId" @click.stop="onUploadDocument(props.dataItem)" title="Add Associated Document"><i class="fa-sharp fa-solid fa-plus"></i></k-button>
                    <k-button class="open-doc__btn" v-if="props.dataItem.associatedDocumentId" @click.stop="onOpenDocument(props.dataItem)" title="View Associated Document"><i class="fa-duotone fa-folder-open"></i></k-button>
                    <k-button class="edit-doc__btn" v-if="props.dataItem.associatedDocumentId" @click.stop="onEditDocument(props.dataItem)" title="Edit Associated Document"><i class="fa-duotone fa-pen-to-square"></i></k-button>

                    <k-button class="delete-item__btn" @click.stop="onDeleteItem(props.dataItem)" title="Delete Document"><i class="fa-duotone fa-trash"></i></k-button>
                </div>
            </template>
            <k-grid-toolbar>
                    <span class="k-textbox k-grid-search k-display-flex">
                        <k-input :style="{ width: '300px' }"
                                  placeholder="Search in all columns..."
                                  :value="searchWord"
                                  :icon-name="'search'"
                                  @input="onSearchChange"></k-input>
                    </span>
                    <k-dropdown class="grid-filter__select" 
                                :data-items="familyFilterSelects" 
                                v-model="selectedFamilyFilter"></k-dropdown>
                    <k-dropdown class="grid-filter__select" 
                                :data-items="filterSelects" 
                                v-model="selectedFilter"></k-dropdown>
                    <div class="view-toggle">
                        <p :class="['toggle-label', { 'active': showActiveView }]">Active</p>
                        <k-switch :on-label="''" :off-label="''" v-model="showActiveView" :size="'small'"></k-switch>
                        <p :class="['toggle-label', { 'active': !showActiveView }]">Expired</p>
                    </div>
                    <div class="grid-button__group">
                        <k-button title="Create Record"
                                    class="create-record__btn"
                                    @click="onCreateItem">
                            <i class="fa-solid fa-passport"></i> Create Record
                        </k-button>
                        <k-button title="Export to Excel"
                                    class="export-excel__btn"
                                    @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                        </k-button>
                    </div>
            </k-grid-toolbar>
        </k-grid>

        <upsert-player-document-dialog :show-dialog="showUploadDocumentDialog"
                                    :player-id="selectedPlayerId"
                                    :identity-id="selectedDocument?.id"
                                    :identity-type="selectedDocument?.type"
                                    :default-folder-name="documentFolderNames.identityDocumentsFolderName"
                                    :current-document="identityDocuments.length > 0 ? identityDocuments[0] : null"
                                    @dialogclosed="toggleUploadDocumentDialog"
                                    @documentsuploaded="getItems(true)"></upsert-player-document-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <upsert-document-dialog :show-dialog="showCreateDialog"
                                :edit-mode="editDocumentMode"
                                :edited-document="selectedDocument"
                                @dialogclosed="toggleCreateDialog"
                                @documentsupdated="getItems"></upsert-document-dialog>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input, Switch } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import upsertCitizenshipDocumentDialog from '../components/upsertCitizenshipDocumentDialog.vue';
    import upsertDocumentDialog from '../components/upsertDocumentDialog.vue';
    
    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import documentHelpers from '../helpers/documentHelpers.js';

    export default {
        name: 'citizenshipDocumentationView',
        data() {
            return {
                allItems: [],
                columns: [
                    { field: 'fullName', title: 'Player' },
                    { field: 'type', title: 'Type' },
                    { field: 'countryOfOrigin', title: 'Country' },
                    { field: 'issuedDate', title: 'Issued Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'expiringDate', title: 'Expiring Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '150' }
                ],
                confirmIconClass: '',
                confirmMsg: '',
                createTitle: 'Create Record',
                deleteItemId: '',
                deleteItemType: '',
                editDocumentMode: false,
                familyColumns: [
                    { field: 'fullName', title: 'Player' },
                    { field: 'familyMemberName', title: 'Family Member' },
                    { field: 'type', title: 'Type' },
                    { field: 'countryOfOrigin', title: 'Country' },
                    { field: 'issuedDate', title: 'Issued Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'expiringDate', title: 'Expiring Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '150' }
                ],
                familyFilterSelects: ['All Records', 'Player Records', 'Family Records'],
                filter: null,
                filteredItems: [],
                filterSelects: ['All Documents', 'Green Cards', 'Passports', 'Visas'],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                group: [],
                identityDocuments: [],
                isLoading: false,
                notificationMsg: '',
                playerColumns: [
                    { field: 'fullName', title: 'Player' },
                    { field: 'type', title: 'Type' },
                    { field: 'countryOfOrigin', title: 'Country' },
                    { field: 'issuedDate', title: 'Issued Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'expiringDate', title: 'Expiring Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '150' }
                ],
                searchWord: '',
                selectedDocument: null,
                selectedFamilyFilter: 'Player Records',
                selectedFilter: 'All Documents',
                selectedPlayerId: null,
                showActiveView: true,
                showConfirmDialog: false,
                showCreateDialog: false,
                showErrorDialog: false,
                showUploadDocumentDialog: false,
                skip: 0,
                sort: [{ field: 'expiringDate', dir: 'asc' }],
                sortable: { allowUnsort: false, mode: 'single' },
                successNotification: false,
                take: 50,
                total: 0
            }
        },
        computed: {
            documentFolderNames() {
                return documentHelpers.documentFolderNames;
            }
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            },
            selectedFamilyFilter(val) {
                this.getData();
            },
            selectedFilter(val) {
                let value;

                switch(val) {
                    case 'Green Cards':
                        value = 'Green Card';
                        break;
                    case 'Passports':
                        value = 'Passport';
                        break;
                    case 'Visas':
                        value = 'Visa';
                        break;
                    case 'All Documents':
                        value = '';
                }

                if (!this.filter) {
                    this.onFilterChange({ filter: { filters: [{ field: 'type', operator: 'contains', value: value }], logic: 'and'} });
                } else {
                    let typeFilterIndex = this.filter.filters.findIndex(filter => filter.field === 'type');

                    if (typeFilterIndex >= 0) {
                        this.filter.filters.splice(typeFilterIndex, 1, { field: 'type', operator: 'contains', value: value });
                    } else {
                        this.filter.filters.push({ field: 'type', operator: 'contains', value: value });
                    }

                    this.onFilterChange({ filter: this.filter });
                }
            },
            showActiveView(val) {
                if (val) {
                    if (!this.sort || this.sort[0].field === 'expiringDate') {
                        this.sort = [{ field: 'expiringDate', dir: 'asc' }];
                    }
                } else {
                    if (!this.sort || this.sort[0].field === 'expiringDate') {
                        this.sort = [{ field: 'expiringDate', dir: 'desc' }];
                    }
                }

                this.getData();
            }
        },
        mounted() {
            this.getItems();
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteItem() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteCitizenshipDocument(tokenResponse.accessToken, this.deleteItemId, this.deleteItemType) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Document deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: "Citizenship Documents",
                    columns: this.columns
                });
            },
            async getAssociatedIdentityDocument() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerDocument(tokenResponse.accessToken, this.selectedDocument.associatedDocumentId)
                    .then((res) => {
                        self.identityDocuments = res.data.data;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    group: this.group,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allItems.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allItems);
                }

                let now = new Date();
                let current = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                
                if (this.showActiveView) {
                    this.filteredItems = this.filteredItems.filter(item => {
                        return !item.expiringDate
                        || item.expiringDate >= current;
                    });
                } else {
                    this.filteredItems = this.filteredItems.filter(item => {
                        return item.expiringDate
                        && item.expiringDate <= current;
                    });
                }

                if (this.selectedFamilyFilter) {
                    if (this.selectedFamilyFilter === 'Player Records') {
                        this.columns = this.playerColumns;
                        this.filteredItems = this.filteredItems.filter(val => !val.familyRecord);
                    } 
                    
                    if (this.selectedFamilyFilter === 'Family Records') {
                        this.columns = this.familyColumns;
                        this.filteredItems = this.filteredItems.filter(val => val.familyRecord);
                    }

                    if (this.selectedFamilyFilter === 'All Records') {
                        this.columns = this.familyColumns;
                    }
                } 

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);

                    let typeFilter = this.filter.filters.find(filter => filter.field === 'type');

                    if (!typeFilter) {
                        this.selectedFilter = 'All Documents';
                    }
                } else {
                    this.selectedFilter = 'All Documents';
                }

                return this.filteredItems;
            },
            async getItems(update = false) {     
                this.isLoading = true;
                await this.$store.dispatch('getCitizenshipDocuments', update);

                this.allItems = cloneDeep(this.$store.state.citizenshipDocuments);
                console.log(this.allItems)
        
                this.getData();
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCreateItem() {
                this.toggleCreateDialog();
            },
            onDeleteItem(item) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deleteItemId = item.id;
                this.deleteItemType = item.type;
                this.confirmMsg = `Are you sure you would like to delete this ${item.type}?`;
                this.toggleConfirmDialog();
            },
            async onEditDocument(identityDoc) {
                this.selectedPlayerId = identityDoc.player_id;
                this.selectedDocument = identityDoc;

                await this.getAssociatedIdentityDocument();

                this.toggleUploadDocumentDialog();
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            async onOpenDocument(identityDoc) {
                this.selectedDocument = identityDoc;

                await this.getAssociatedIdentityDocument();

                if (this.identityDocuments.length > 0) {
                    let selectedPlayerDocument = this.identityDocuments[0];
                    let routeData = this.$router.resolve({name: 'documentview', query: { id: selectedPlayerDocument.documentId }});
                    window.open(routeData.href, '_blank');
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRowClick(evt) {
                this.editDocumentMode = true;
                this.selectedDocument = evt.dataItem;

                this.toggleCreateDialog();
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            onUploadDocument(identityDoc) {
                this.selectedPlayerId = identityDoc.player_id;
                this.selectedDocument = identityDoc;

                this.toggleUploadDocumentDialog();
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleCreateDialog() {
                this.showCreateDialog = !this.showCreateDialog;

                if (!this.showCreateDialog) {
                    this.editDocumentMode = false;
                    this.selectedDocument = null;
                }
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleUploadDocumentDialog() {
                this.showUploadDocumentDialog = !this.showUploadDocumentDialog;
            },
            async updateItem(document, documentType, documentTypeKey) {
                let self = this;

                this.isLoading = true;
                this.toggleCreateDialog();

                let request = {
                    documentType: documentType
                }

                request[documentTypeKey] = document;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateCitizenshipDocument(tokenResponse.accessToken, request) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Document updated successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-dialog': Dialog,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-loader': Loader,
            'view-header': viewHeader,
            'k-input': Input,
            'k-button': Button,
            'confirm-dialog': confirmDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'upsert-document-dialog': upsertCitizenshipDocumentDialog,
            'upsert-player-document-dialog': upsertDocumentDialog,
            'k-switch': Switch
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/citizenshipDocumentationView.css';
</style>