<template>
    <div class="pipeline-view__container contract-management-view">
        <view-header :title="'Contract Management'">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-file-signature"></i>
            </template>
        </view-header>
        <k-grid
            :data-items="filteredItems"
            :data-item-key="'id'"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="true"
            :filter="filter"
            :group="group"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:pipelineActions="{ props }"> 
                <div class="pipeline-actions__container">
                    <k-button class="delete-item__btn" @click.stop="onDeleteItem(props.dataItem)" title="Delete Contract"><i class="fa-duotone fa-trash"></i></k-button>
                </div>
            </template>
            <template v-slot:hasBonusCell="{ props }">
                <td class="contract-bonus-cell">
                    <span v-if="props.dataItem.bonusCount && props.dataItem.bonusCount > 0"><i class="fa-solid fa-circle-check"></i></span>
                    <span v-if="!props.dataItem.bonusCount || props.dataItem.bonusCount === 0"></span>
                </td>
            </template>
            <k-grid-toolbar>
                    <span class="k-textbox k-grid-search k-display-flex">
                        <k-input :style="{ width: '300px' }"
                                  placeholder="Search in all columns..."
                                  :value="searchWord"
                                  :icon-name="'search'"
                                  @input="onSearchChange"></k-input>
                    </span>
                    <k-dropdown class="grid-filter__select" 
                                :data-items="filterSelects" 
                                v-model="selectedFilter"></k-dropdown>
                    <div class="view-toggle">
                        <p :class="['toggle-label', { 'active': showActiveView }]">Active</p>
                        <k-switch :on-label="''" :off-label="''" v-model="showActiveView" :size="'small'"></k-switch>
                        <p :class="['toggle-label', { 'active': !showActiveView }]">Expired</p>
                    </div>
                    <div class="grid-button__group">
                        <k-button title="Create Contract"
                                    class="create-contract__btn"
                                    @click="onCreateItem">
                            <i class="fa-solid fa-pen-field"></i> Create Contract
                        </k-button>
                        <k-button title="Create SPAC"
                                    class="export-excel__btn"
                                    @click="toggleSpacDialog">
                            <i class="fa-solid fa-pen-field"></i> Create SPAC 
                        </k-button>
                        <k-button title="Export to Excel"
                                    class="export-excel__btn"
                                    @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                        </k-button>
                        <div class="settings__icon" title="Seasonal Settings" @click="toggleSettingsDialog">
                            <i class="fa-duotone fa-gear"></i>
                        </div>
                    </div>
            </k-grid-toolbar>
        </k-grid>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem">
        </confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <seasonal-settings-dialog :show-dialog="showSettingsDialog"
                                    @dialogclosed="toggleSettingsDialog">
        </seasonal-settings-dialog>

        <create-contract-dialog :show-dialog="showCreateDialog"
                                @contractcreated="getItems"
                                @dialogclosed="toggleCreateDialog">
        </create-contract-dialog>

        <create-spac-dialog :show-dialog="showSpacDialog"
                            @dialogclosed="toggleSpacDialog">
        </create-spac-dialog>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input, Switch } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import seasonalSettingsDialog from '../components/seasonalSettingsDialog.vue';
    import createContractDialog from '../components/createContractDialog.vue';
    import createSpacDialog from '../components/createSpacDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import contractHelpers from '../helpers/contractHelpers.js';

    export default {
        name: 'playerManagementView',
        data() {
            return {
                allItems: [],
                columns: [
                    { field: 'contractTitle', title: 'Name' },
                    { field: 'contractType', title: 'Type' },
                    { field: 'expiration_field', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                confirmIconClass: '',
                confirmMsg: '',
                createTitle: 'Create Player',
                deleteItemId: '',
                filter: null,
                filteredItems: [],
                filterSelects: ['All Contracts', 'Agency Contracts', 'Endorsement Contracts', 'Equipment Contracts', 'Playing Contracts'],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                group: [],
                isLoading: false,
                newContractType: '',
                newContractTypeSelected: false,
                notificationMsg: '',
                pipelineFilter: 'All',
                saveNewItemClicked: false,
                searchWord: '',
                selectedFilter: 'All Contracts',
                showActiveView: true,
                showConfirmDialog: false,
                showCreateDialog: false,
                showErrorDialog: false,
                showSettingsDialog: false,
                showSpacDialog: false,
                skip: 0,
                sort: [{ field: 'expiration_field', dir: 'asc' }],
                sortable: { allowUnsort: false, mode: 'single' },
                successNotification: false,
                take: 50,
                total: 0
            }
        }, 
        computed: {
            agencyContractColumns() {
                return contractHelpers.agencyContractColumns;
            },
            allContractColumns() {
                return contractHelpers.allContractColumns;
            },
            endorsementContractColumns() {
                return contractHelpers.endorsementContractColumns;
            },
            equipmentContractColumns() {
                return contractHelpers.equipmentContractColumns;
            },
            playerContractColumns() {
                return contractHelpers.playerContractColumns;
            }
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            },
            selectedFilter(val) {
                let value;

                switch(val) {
                    case 'Agency Contracts':
                        value = 'Agency';
                        this.columns = cloneDeep(this.agencyContractColumns);
                        break;
                    case 'Endorsement Contracts':
                        this.columns = cloneDeep(this.endorsementContractColumns);
                        value = 'Endorsement';
                        break;
                    case 'Equipment Contracts':
                        this.columns = cloneDeep(this.equipmentContractColumns);
                        value = 'Equipment';
                        break;
                    case 'Playing Contracts':
                        this.columns = cloneDeep(this.playerContractColumns);
                        value = 'Playing';
                        break;
                    case 'All Contracts':
                        this.columns = cloneDeep(this.allContractColumns);
                        value = '';
                }
                
                if (!this.filter) {
                    this.onFilterChange({ filter: { filters: [{ field: 'contractType', operator: 'contains', value: value }], logic: 'and'} });
                } else {
                    let typeFilterIndex = this.filter.filters.findIndex(filter => filter.field === 'contractType' || (filter.filters && filter.filters.some(f => f.field === 'contractType')));

                    if (typeFilterIndex >= 0) {
                        this.filter.filters.splice(typeFilterIndex, 1, { field: 'contractType', operator: 'contains', value: value })
                    } else {
                        this.filter.filters.push({ field: 'contractType', operator: 'contains', value: value });
                    }

                    this.onFilterChange({ filter: this.filter });
                }
            },
            showActiveView(val) {
                if (val) {
                    if (!this.sort || this.sort[0].field === 'expiration_field') {
                        this.sort = [{ field: 'expiration_field', dir: 'asc' }];
                    }
                } else {
                    if (!this.sort || this.sort[0].field === 'expiration_field') {
                        this.sort = [{ field: 'expiration_field', dir: 'desc' }];
                    }
                }

                this.getData();
            }
        },
        mounted() {
            this.getItems();
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteItem() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteContract(tokenResponse.accessToken, this.deleteItemId) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Contract deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: "Contracts",
                    columns: this.columns
                });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    group: this.group,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allItems.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allItems);
                }

                let now = new Date();
                let current = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                
                if (this.showActiveView) {
                    this.filteredItems = this.filteredItems.filter(item => {
                        return !item.expiration_field 
                        || item.expiration_field >= current;
                    });
                } else {
                    this.filteredItems = this.filteredItems.filter(item => {
                        return item.expiration_field
                        && item.expiration_field <= current;
                    });
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);

                    let typeFilter = this.filter.filters.find(filter => filter.field === 'contractType' || (filter.filters && filter.filters.some(f => f.field === 'contractType')));

                    if (!typeFilter) {
                        this.selectedFilter = 'All Contracts';
                    }
                } else {
                    this.selectedFilter = 'All Contracts';
                }

                return this.filteredItems;
            },
            async getItems(update = false) {     
                this.isLoading = true;
                await this.$store.dispatch('getContracts', update);

                this.allItems = cloneDeep(this.$store.state.contracts);
                console.log(this.allItems)
        
                this.getData();
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCreateItem() {
                this.toggleCreateDialog();
            },
            onDeleteItem(item) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deleteItemId = item.contractId;
                this.confirmMsg = `Are you sure you would like to delete <span>${item.contractTitle}</span>?`;
                this.toggleConfirmDialog();
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRowClick(evt) {
                this.$router.push(`/ContractDetail?id=${evt.dataItem.contractId}`);
            },
            onSaveNewItemClicked() {
                this.saveNewItemClicked = true;
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            resetCreateClick() {
                this.saveNewItemClicked = false;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleCreateDialog() {
                this.showCreateDialog = !this.showCreateDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleSettingsDialog() {
                this.showSettingsDialog = !this.showSettingsDialog;
            },
            toggleSpacDialog() {
                this.showSpacDialog = !this.showSpacDialog;
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-loader': Loader,
            'view-header': viewHeader,
            'k-input': Input,
            'k-button': Button,
            'confirm-dialog': confirmDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'create-contract-dialog': createContractDialog,
            'k-dropdown': DropDownList,
            'seasonal-settings-dialog': seasonalSettingsDialog,
            'create-spac-dialog': createSpacDialog,
            'k-switch': Switch
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/contractManagementView.css';
</style>