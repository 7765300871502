import { createStore } from 'vuex';

import cloneDeep from 'lodash.clonedeep';

import auth from '../authConfig';
import apiService from '../api/api.service.js';
import formatHelpers from '../helpers/formatHelpers.js';
import contractHelpers from '../helpers/contractHelpers';
import citizenshipDocHelpers from '@/helpers/citizenshipDocHelpers';
import invoiceHelpers from '@/helpers/invoiceHelpers';
import playerHelpers from '@/helpers/playerHelpers';

export default createStore({
  state: {
    allSeasonalSettings: [],
    citizenshipDocuments: [],
    contractDataModel: {
        aav: null,
        age: null,
        amountYearOne: null,
        amountYearTwo: null,
        amountYearThree: null,
        amountYearFour: null,
        amountYearFive: null,
        amountYearSix: null,
        amountYearSeven: null,
        amountYearEight: null,
        amountYearNine: null,
        amountYearTen: null,
        contractId: null,
        contractType: null,
        endDate: new Date(null),
        expirationStatus: null,
        fullName: null,
        playerId: null,
        startDate: new Date(null)
    },
    contracts: [],
    dashboardContractsData: [],
    errorAlert: false,
    errorMsg: '',
    errorTitle: '',
    injuries: [],
    invoices: [],
    players: [],
    nhlApiTeams: [],
    noTradeClauses: [],
    seasonalSettings: null,
    signingBonuses: [],
    scoutingReports: [],
    user: null
  },
  getters: {
  },
  mutations: {
    updateCitizenshipDocuments(state, documents) {
      state.citizenshipDocuments = documents;
    },
    updateContracts(state, contracts) {
      state.contracts = contracts;
    },
    updateDashboardContractsData(state, data) {
      state.dashboardContractsData = data;
    },
    updateErrorAlert(state, errorData) {
      state.errorMsg = errorData.msg;
      state.errorTitle = errorData.title;
      state.errorAlert = errorData.isError;
    },
    updateInjuries(state, injuries) {
      state.injuries = injuries;
    },
    updateInvoices(state, invoices) {
      state.invoices = invoices;
    },
    updateNhlApiTeams(state, teams) {
      state.nhlApiTeams = teams;
    },
    updateNoTradeClauses(state, clauses) {
      state.noTradeClauses = clauses;
    },
    updatePlayers(state, players) {
      state.players = players;
    },
    updateScoutingReports(state, reports) {
      state.scoutingReports = reports;
    },
    updateSeasonalSettings(state, settings) {
      state.allSeasonalSettings = settings;

      settings.sort((a, b) => b.seasonStartYear - a.seasonStartYear);
      let currentSettings = settings.find(settings => settings.isCurrent);

      if (!currentSettings) {
          currentSettings = settings[0];
      }
      state.seasonalSettings = currentSettings;
    },
    updateSigningBonuses(state, bonuses) {
      state.signingBonuses = bonuses;
    },
    updateUser(state, user) {
        state.user = user;
    }
  },
  actions: {
    async getActiveSigningBonuses({ commit, state }) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetActiveSigningBonuses(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let bonuses = res.data.data;

                    bonuses.forEach(bonus => {
                        bonus.bonusDate = formatHelpers.formatDate(bonus.bonusDate);
                    });

                    commit('updateSigningBonuses', bonuses);
                }
            })
    },
    async getCitizenshipDocuments({ commit, state }, update = false) {
      if (state.citizenshipDocuments.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetCitizenshipDocuments(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let documents = [...res.data.data.greenCards, ...res.data.data.passports, ...res.data.data.visas];

                    documents.forEach(document => {
                      citizenshipDocHelpers.citizenshipDocDateFields.forEach(field => {
                        document[field] = formatHelpers.formatDate(document[field]);
                      });

                      if (!document.familyRecord) {
                        document.familyMemberName = document.fullName;
                      }
                    });

                    documents.sort((a, b) => a.expiringDate - b.expiringDate);

                    commit('updateCitizenshipDocuments', documents);
                }
            }).catch(error => {
                console.log(error);
  
                let errorText = '';
  
                if(error.response?.data?.data) {
                  errorText = "Error: \n\n" + error.response?.data?.data;
                } 
  
                let errorData = {
                    isError: true,
                    title: 'Failed to Fetch Citizenship Documents',
                    msg: errorText
                };
  
                commit('updateErrorAlert', errorData);  
            });
      }
    },
    async getContracts({ commit, state }, update = false) {
      if (state.contracts.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetContracts(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let contracts = res.data.data;

                    contracts.forEach(contract => {
                      contractHelpers.contractDateFields.forEach(field => {
                          contract[field] = formatHelpers.formatDate(contract[field]);
                      });

                      if (contract.contractType === contractHelpers.playingContractType) {
                        contract.contractyears_field = contractHelpers.getContractYears(contract);
                      }
                      
                      contract.signing_field = contractHelpers.getContractSigningField(contract);
                      contract.expiration_field = contractHelpers.getContractExpirationField(contract);
                    });
                    
                    commit('updateContracts', contracts);
                }
            }).catch(error => {
                console.log(error);
  
                let errorText = '';
  
                if(error.response?.data?.data) {
                  errorText = "Error: \n\n" + error.response?.data?.data;
                } 
  
                let errorData = {
                    isError: true,
                    title: 'Failed to Fetch Contracts',
                    msg: errorText
                };
  
                commit('updateErrorAlert', errorData);  
            });
      }  
    },
    async getDashboardContractsData({ commit, state }, currentSeasonStart) {
      let tokenResponse = await auth.acquireTokenResponse();

      return apiService.GetDashboardContractsData(tokenResponse.accessToken, currentSeasonStart)
        .then((res) => {
          var data = res.data.data.filter(contract => new Date(contract.endDate) >= new Date());

          let playersWithContracts = [...new Set(data.map(contract => contract.playerId))];
                 
            if (playersWithContracts.length > 0) {
                let duplicatePlayers = [];
                let mergedContracts = [];

                playersWithContracts.forEach(playerId => {
                    let contracts = data.filter(contract => contract.playerId === playerId);

                    contracts.forEach(contract => {
                      contract.startDate = new Date(contract.startDate);
                      contract.endDate = new Date(contract.endDate);
                    });

                    if (contracts.length > 1) {
                        duplicatePlayers.push(playerId);

                        contracts.sort((a, b) => a.startDate - b.startDate);

                        let mergedContract = cloneDeep(state.contractDataModel);

                        contracts.forEach((contract) => {
                            mergedContract.aav = mergedContract.aav ?? contract.aav;
                            mergedContract.playerAge = mergedContract.playerAge ?? contract.playerAge;
                            mergedContract.amountYearOne = mergedContract.amountYearOne ?? contract.amountYearOne;
                            mergedContract.amountYearTwo = mergedContract.amountYearTwo ?? contract.amountYearTwo;
                            mergedContract.amountYearThree = mergedContract.amountYearThree ?? contract.amountYearThree;
                            mergedContract.amountYearFour = mergedContract.amountYearFour ?? contract.amountYearFour;
                            mergedContract.amountYearFive = mergedContract.amountYearFive ?? contract.amountYearFive;
                            mergedContract.amountYearSix = mergedContract.amountYearSix ?? contract.amountYearSix;
                            mergedContract.amountYearSeven = mergedContract.amountYearSeven ?? contract.amountYearSeven;
                            mergedContract.amountYearEight = mergedContract.amountYearEight ?? contract.amountYearEight;
                            mergedContract.amountYearNine = mergedContract.amountYearNine ?? contract.amountYearNine;
                            mergedContract.amountYearTen = mergedContract.amountYearTen ?? contract.amountYearTen;
                            mergedContract.contractId = mergedContract.contractId ?? contract.contractId;
                            mergedContract.contractType = mergedContract.contractType ?? contract.contractType;
                            mergedContract.expirationStatus = mergedContract.expirationStatus ?? contract.expirationStatus;
                            mergedContract.fullName = mergedContract.fullName ?? contract.fullName;
                            mergedContract.playerId = mergedContract.playerId ?? contract.playerId;
                            mergedContract.startDate = (mergedContract.startDate.getTime() !== new Date(null).getTime() && mergedContract.startDate < contract.startDate) 
                              ? mergedContract.startDate 
                              : contract.startDate;
                            mergedContract.endDate = mergedContract.endDate > contract.endDate ? mergedContract.endDate : contract.endDate;
                        });

                        mergedContracts.push(mergedContract);
                    }
                });

                if (duplicatePlayers.length > 0) {
                    data = data.filter(contract => !duplicatePlayers.some(playerId => playerId === contract.playerId ));
                }

                if (mergedContracts.length > 0) {
                    data = [...data, ...mergedContracts];
                }
            }

          commit('updateDashboardContractsData', data);
        }).catch(error => {
          console.log(error);
  
          let errorText = '';

          if(error.response?.data?.data) {
            errorText = "Error: \n\n" + error.response?.data?.data;
          } 

          let errorData = {
              isError: true,
              title: 'Failed to Fetch Contracts',
              msg: errorText
          };

          commit('updateErrorAlert', errorData);  
        });
    },
    async getInjuries({ commit, state }, update = false) {
      if (state.injuries.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetActiveInjuries(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let injuries = res.data.data;

                    injuries.forEach(injury => {
                      injury.injuryDate = formatHelpers.formatDate(injury.injuryDate);
                      injury.returnDate = formatHelpers.formatDate(injury.returnDate);
                    });

                    commit('updateInjuries', injuries);
                }
            }).catch(error => {
                console.log(error);
  
                let errorText = '';
  
                if(error.response?.data?.data) {
                  errorText = "Error: \n\n" + error.response?.data?.data;
                } 
  
                let errorData = {
                    isError: true,
                    title: 'Failed to Fetch Injury Records',
                    msg: errorText
                };
  
                commit('updateErrorAlert', errorData);  
            });
      }
    },
    async getInvoices({ commit, state }, update = false) {
      if (state.invoices.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetAllInvoices(tokenResponse.accessToken)
          .then(res => {
            if (res.data.data) {
              let invoices = res.data.data;

              invoices.forEach(invoice => {
                invoiceHelpers.invoiceDateFields.forEach(field => {
                  invoice[field] = formatHelpers.formatDate(invoice[field]);
                });
                invoice.invoiceYear = invoice.invoiceDate.getFullYear();
              });

              commit('updateInvoices', invoices);
            }
          }).catch(error => {
            console.log(error);
  
            let errorText = '';

            if(error.response?.data?.data) {
              errorText = "Error: \n\n" + error.response?.data?.data;
            } 

            let errorData = {
                isError: true,
                title: 'Failed to Fetch Invoices',
                msg: errorText
            };

            commit('updateErrorAlert', errorData);  
          })
      }
    },
    async getNhlApiTeams({ commit, state }) {
      if (state.nhlApiTeams.length === 0) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetNhlApiTeams(tokenResponse.accessToken)
          .then(res => {
            commit('updateNhlApiTeams', res.data.data);
          }).catch(error => {
            console.log(error);
          });
      }
    },
    async getNoTradeClauses({ commit, state }, update = false) {
      if (state.noTradeClauses.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetNoTradeClauses(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let clauses = res.data.data;

                    clauses.forEach(clause => {
                      clause.dueDate = formatHelpers.formatDate(clause.dueDate);
                    });

                    commit('updateNoTradeClauses', clauses);
                }
            }).catch(error => {
                console.log(error);
  
                let errorText = '';
  
                if(error.response?.data?.data) {
                  errorText = "Error: \n\n" + error.response?.data?.data;
                } 
  
                let errorData = {
                    isError: true,
                    title: 'Failed to Fetch No Trade Reporting Data',
                    msg: errorText
                };
  
                commit('updateErrorAlert', errorData);  
            });
      }
    },
    async getPlayers({ commit, state }, update = false) {
      if (state.players.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetPlayers(tokenResponse.accessToken)
            .then(res => {
                if (res.data.data) {
                    let players = res.data.data;

                    players.forEach(player => {
                      player.dob = formatHelpers.formatDate(player.dob);
                      player.fullName = `${player.firstName} ${player.lastName}`;
                      player.contractValueField = player.aav ?? player.annualContractValue;
                      player.positionString = playerHelpers.getAbbreviatedPositions(player.positions);
                    });

                    commit('updatePlayers', players);
                }
            }).catch(error => {
                console.log(error);
  
                let errorText = '';
  
                if(error.response?.data?.data) {
                  errorText = "Error: \n\n" + error.response?.data?.data;
                } 
  
                let errorData = {
                    isError: true,
                    title: 'Failed to Fetch Players',
                    msg: errorText
                };
  
                commit('updateErrorAlert', errorData);  
            });
      }  
    },
    async getScoutingReports({ commit, state }, update = false) {
      if (state.scoutingReports.length === 0 || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetPlayerScoutingReports(tokenResponse.accessToken)
          .then(res => {
            if (res.data.data) {
              let reports = res.data.data;

              reports.forEach(report => {
                report.poi = report.playerOfInterest === 'Yes' ? 'Y' : 'N';
                report.gameDate = formatHelpers.formatDate(report.gameDate);
                report.positionString = playerHelpers.getAbbreviatedPositions(report.playerPositions);
              });

              commit('updateScoutingReports', reports);
            }
          }).catch(error => {
            console.log(error);

            let errorText = '';

            if(error.response?.data?.data) {
              errorText = "Error: \n\n" + error.response?.data?.data;
            } 

            let errorData = {
                isError: true,
                title: 'Failed to Fetch Reports',
                msg: errorText
            };

            commit('updateErrorAlert', errorData);  
        });
      }
    },
    async getSeasonalSettings({ commit, state }, update = false) {
      if (!state.seasonalSettings || update) {
        let tokenResponse = await auth.acquireTokenResponse();

        return apiService.GetSeasonalSettings(tokenResponse.accessToken) 
          .then(res => {
            if (res.data.data) {
              commit('updateSeasonalSettings', res.data.data);
            }
          }).catch(error => {
            console.log(error);
  
            let errorText = '';

            if(error.response?.data?.data) {
              errorText = "Error: \n\n" + error.response?.data?.data;
            } 

            let errorData = {
                isError: true,
                title: 'Failed to Fetch Seasonal Settings',
                msg: errorText
            };

            commit('updateErrorAlert', errorData);  
          });
      }
    },
    async getUser({ commit, state }) {
        if (!state.user) {
            const msalAppInstance = await auth.init();

           return msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        auth.login();
                    }
                }
    
                let userId = auth.user()?.localAccountId;
    
                let tokenResponse = await auth.acquireTokenResponse();
    
                return apiService.GetUser(tokenResponse.accessToken, userId)
                    .then((res) => {
                        commit('updateUser', res.data.data);
                    }).catch(error => {
                        console.log(error);
                    })
            });
        }
    },
  },
  modules: {
  }
});
