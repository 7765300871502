import axios from "axios";
var API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
//var API_ENDPOINT = "https://localhost:44325/";
//var API_ENDPOINT = "https://localhost:7175/";

export default {
  GetProfilePhoto(token, azureId) {
    return axios.get(API_ENDPOINT + `api/GraphAPI/GetProfilePhoto?azureId=${azureId}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetUser(token, azureId) {
    return axios.get(API_ENDPOINT + `api/GraphAPI/GetUser?azureId=${azureId}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetAzureUsers(token) {
    return axios.get(API_ENDPOINT + `api/GraphAPI/GetAzureUsers`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetUncompletedTasks(token) {
    return axios.get(API_ENDPOINT + `api/Tasks/GetUncompletedTasks`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpsertTask(token, request) {
    return axios.post(API_ENDPOINT + 'api/Tasks/UpsertTask', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteTask(token, taskId) {
    return axios.delete(API_ENDPOINT + `api/Tasks/DeleteTask?taskId=${taskId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpsertDraftDetail(token, request) {
    return axios.post(API_ENDPOINT + 'api/DraftDetails/UpsertDetail', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteDraftDetail(token, request) {
    return axios.post(API_ENDPOINT + `api/DraftDetails/DeleteDetail`, request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpsertRightsDetail(token, request) {
    return axios.post(API_ENDPOINT + 'api/NHLRightsDetails/UpsertDetail', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteRightsDetail(token, request) {
    return axios.post(API_ENDPOINT + `api/NHLRightsDetails/DeleteDetail`, request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetInvoiceSummaryData(token) {
    return axios.get(API_ENDPOINT + `api/Invoices/GetInvoiceSummaryData`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdatePlayerStatistics(token) {
    return axios.get(API_ENDPOINT + `api/BackgroundWorkers/UpdatePlayerStatistics`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetDashboardContractsData(token, yearOne) {
    return axios.get(API_ENDPOINT + `api/Contracts/GetDashboardContractsData?yearOne=${yearOne}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetDashboardStatisticsData(token) {
    return axios.get(API_ENDPOINT + `api/Players/GetDashboardStatisticsData`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetWatchListGameLogs(token) {
    return axios.get(API_ENDPOINT + `api/WatchList/GetWatchListGameLogs`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpsertWatchListGameLogMisc(token, request) {
    return axios.post(API_ENDPOINT + `api/WatchList/UpsertWatchListGameLogMisc`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetWatchListPlayers(token) {
    return axios.get(API_ENDPOINT + `api/WatchList/GetWatchListPlayers`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  CreateWatchListPlayer(token, request) {
    return axios.post(API_ENDPOINT + `api/WatchList/CreateWatchListPlayer`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  DeleteWatchListPlayer(token, playerId) {
    return axios.delete(API_ENDPOINT + `api/WatchList/DeleteWatchListPlayer?eliteProspectsPlayerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetDraftEligiblePlayers(token, yearCount, includeAll = false) {
    return axios.get(API_ENDPOINT + `api/Players/GetDraftEligiblePlayers?yearCount=${yearCount}&includeAll=${includeAll}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdatePlayersDraftOrder(token, request) {
    return axios.post(API_ENDPOINT + 'api/Players/UpdatePlayersDraftOrder', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetPlayerEvents(token) {
    return axios.get(API_ENDPOINT + `api/Events/GetPlayerEvents`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateEventPin(token, request) {
    return axios.post(API_ENDPOINT + 'api/Events/UpdateEventPin', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetNoTradeClauses(token) {
    return axios.get(API_ENDPOINT + `api/NoTradeClauses/GetNoTradeClauses`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateNoTradeClause(token, request) {
    return axios.post(API_ENDPOINT + 'api/NoTradeClauses/UpdateNoTradeClause', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteNoTradeClause(token, clauseId) {
    return axios.delete(API_ENDPOINT + `api/NoTradeClauses/DeleteNoTradeClause?clauseId=${clauseId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetNoTradeClauseNotes(token, clauseId) {
    return axios.get(API_ENDPOINT + `api/NoTradeClauses/GetNoTradeClauseNotes?clauseId=${clauseId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  CreateNoTradeClauseNote(token, request) {
    return axios.post(API_ENDPOINT + 'api/NoTradeClauses/CreateNoTradeClauseNote', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpsertPlayerAssociation(token, request) {
    return axios.post(API_ENDPOINT + 'api/PlayerAssociations/UpsertPlayerAssociation', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeletePlayerAssociation(token, associationId, playerId) {
    return axios.delete(API_ENDPOINT + `api/PlayerAssociations/DeletePlayerAssociation?associationId=${associationId}&playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetActiveInjuries(token) {
    return axios.get(API_ENDPOINT + `api/Injuries/GetActiveInjuries`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpsertPlayerInjury(token, request) {
    return axios.post(API_ENDPOINT + 'api/Injuries/UpsertInjury', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeletePlayerInjury(token, injuryId, playerId) {
    return axios.delete(API_ENDPOINT + `api/Injuries/DeleteInjury?injuryId=${injuryId}&playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetDocumentRootFolders(token) {
    return axios.get(API_ENDPOINT + `api/HockeyDocuments/GetRootFolderNames`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetPlayerDocuments(token, playerId) {
    return axios.get(API_ENDPOINT + `api/HockeyDocuments/GetPlayerDocuments?playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  GetPlayerDocument(token, documentId) {
    return axios.get(API_ENDPOINT + `api/HockeyDocuments/GetPlayerDocument?documentId=${documentId}`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UploadHockeyDocuments(token, request, playerId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UploadDocuments?playerId=${playerId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UploadContractDocument(token, request, playerId, contractId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UploadContractDocument?playerId=${playerId}&contractId=${contractId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UploadIdentityDocument(token, request, playerId, identityId, identityType) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UploadIdentityDocument?playerId=${playerId}&identityId=${identityId}&identityType=${identityType}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UploadPictureDocument(token, request, playerId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UploadPictureDocument?playerId=${playerId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UploadScoutingDocument(token, request, playerId, reportId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UploadScoutingDocument?playerId=${playerId}&reportId=${reportId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateContractDocument(token, request, contractId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UpdateContractDocument?contractId=${contractId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateIdentityDocument(token, request, identityId, identityType) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UpdateIdentityDocument?identityId=${identityId}&identityType=${identityType}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateScoutingDocument(token, request, reportId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UpdateScoutingDocument?reportId=${reportId}`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdatePictureDocument(token, request) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UpdatePictureDocument`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  UpdateHockeyDocument(token, request) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/UpdateDocument`, request, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
  },
  DeleteHockeyDocument(token, document) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/DeleteDocument`, document, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetHockeyDocument(token, documentId) {
    return axios.get(API_ENDPOINT + `api/HockeyDocuments/GetDocument?documentId=${documentId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetHockeyDocumentPreviewLink(token, fileId) {
    return axios.post(API_ENDPOINT + `api/HockeyDocuments/GetDocumentPreviewLink?fileId=${fileId}`, {}, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetCitizenshipDocuments(token) {
    return axios.get(API_ENDPOINT + "api/CitizenshipDocuments/GetCitizenshipDocuments", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  CreateCitizenshipDocument(token, request) {
    return axios.post(API_ENDPOINT + 'api/CitizenshipDocuments/CreateCitizenshipDocument', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpdateCitizenshipDocument(token, request) {
    return axios.post(API_ENDPOINT + 'api/CitizenshipDocuments/UpdateCitizenshipDocument', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteCitizenshipDocument(token, documentId, documentType) {
    return axios.delete(API_ENDPOINT + `api/CitizenshipDocuments/DeleteCitizenshipDocument?documentId=${documentId}&documentType=${documentType}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetCitizenshipDocDetail(token, documentId, documentType) {
    return axios.get(API_ENDPOINT + `api/CitizenshipDocuments/GetCitizenshipDocumentDetail?documentId=${documentId}&documentType=${documentType}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetNhlApiTeamRoster(token, teamId, currentSeason) {
    return axios.get(API_ENDPOINT + `api/NHLAPI/GetNHLTeamRoster?teamId=${teamId}&currentSeason=${currentSeason}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
  },
  GetNhlApiTeamSchedule(token, teamId) {
    return axios.get(API_ENDPOINT + `api/NHLAPI/GetNHLTeamSchedule?teamId=${teamId}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
  },
  GetNhlApiTeams(token) {
    return axios.get(API_ENDPOINT + 'api/NHLAPI/GetNHLTeams', {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
  },
  getNhlApiPlayerMilestones(token, playerId) {
    return axios.get(API_ENDPOINT + `api/NHLAPI/GetNHLPlayerData?playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
  },
  getNhlApiPlayerMilestone(playerId, season) {
    return axios.get(`https://statsapi.web.nhl.com/api/v1/people/${playerId}/stats?stats=statsSingleSeason&season=${season}`);
  },
  GetContracts(token) {
    return axios.get(API_ENDPOINT + "api/Contracts/GetContracts", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetPlayingContract(token, playerId) {
    return axios.get(API_ENDPOINT + `api/Contracts/GetPlayingContract?playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  CreateContract(token, request) {
    return axios.post(API_ENDPOINT + 'api/Contracts/CreateContract', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  CreateSPAC(token, request) {
    return axios.post(API_ENDPOINT + 'api/Contracts/GenerateSPAC', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpdateContract(token, request) {
    return axios.post(API_ENDPOINT + 'api/Contracts/UpdateContract', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteContract(token, contractId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeleteContract?contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteEndorsementPayout(token, payoutId, contractId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeleteEndorsementPayout?payoutId=${payoutId}&contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteEndorsementProductCredit(token, productCreditId, contractId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeleteEndorsementProductCredit?productCreditId=${productCreditId}&contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeletePerformanceBonus(token, bonusId, contractId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeletePerformanceBonus?bonusId=${bonusId}&contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteNHLSigningBonus(token, bonusId, contractId, playerId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeleteNHLSigningBonus?bonusId=${bonusId}&contractId=${contractId}&playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteELCPerformanceBonus(token, bonusId, contractId) {
    return axios.delete(API_ENDPOINT + `api/Contracts/DeleteELCPerformanceBonus?bonusId=${bonusId}&contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetContractDetail(token, contractId) {
    return axios.get(API_ENDPOINT + `api/Contracts/GetContractDetail?contractId=${contractId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetPlayers(token) {
    return axios.get(API_ENDPOINT + "api/Players/GetPlayers", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetPlayersWithActiveContracts(token) {
    return axios.get(API_ENDPOINT + "api/Players/GetPlayersWithActiveContracts", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  CreatePlayer(token, request) {
    return axios.post(API_ENDPOINT + 'api/Players/CreatePlayer', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  CreatePlayerNote(token, request) {
    return axios.post(API_ENDPOINT + 'api/Players/CreatePlayerNote', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpdatePlayer(token, request) {
    return axios.post(API_ENDPOINT + 'api/Players/UpdatePlayer', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeletePlayer(token, playerId) {
    return axios.delete(API_ENDPOINT + `api/Players/DeletePlayer?playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetPlayerDetail(token, playerId) {
    return axios.get(API_ENDPOINT + `api/Players/GetPlayerDetail?playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetAllInvoices(token) {
    return axios.get(API_ENDPOINT + "api/Invoices/GetAllInvoices", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  CreateInvoice(token, request) {
    return axios.post(API_ENDPOINT + 'api/Invoices/CreateInvoice', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  CreateInvoiceNote(token, request) {
    return axios.post(API_ENDPOINT + 'api/Invoices/CreateInvoiceNote', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  CreateInvoicePDF(token, request) {
    return axios.post(API_ENDPOINT + 'api/Invoices/GenerateInvoicePDF', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpdateInvoice(token, request) {
    return axios.post(API_ENDPOINT + 'api/Invoices/UpdateInvoice', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteInvoice(token, invoiceId) {
    return axios.delete(API_ENDPOINT + `api/Invoices/DeleteInvoice?invoiceId=${invoiceId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteInvoiceItem(token, itemId, invoiceId) {
    return axios.delete(API_ENDPOINT + `api/Invoices/DeleteInvoiceItem?itemId=${itemId}&invoiceId=${invoiceId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteInvoiceNote(token, noteId, invoiceId) {
    return axios.delete(API_ENDPOINT + `api/Invoices/DeleteInvoiceNote?noteId=${noteId}&invoiceId=${invoiceId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteInvoicePayment(token, request) {
    return axios.post(API_ENDPOINT + 'api/Invoices/DeleteInvoicePayment', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetInvoiceDetail(token, invoiceId, playerId) {
    return axios.get(API_ENDPOINT + `api/Invoices/GetInvoiceDetail?invoiceId=${invoiceId}&playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  GetSeasonalSettings(token) {
    return axios.get(API_ENDPOINT + "api/Invoices/GetSeasonalSettings", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  UpsertSeasonalSettings(token, request) {
    return axios.post(API_ENDPOINT + "api/Invoices/UpdateSeasonalSettings", request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetUpcomingBirthdays(token) {
    return axios.get(API_ENDPOINT + "api/Players/GetPlayersUpcomingBirthdays", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },

  GetRecentlyAddedPlayers(token) {
    return axios.get(API_ENDPOINT + "api/Players/GetRecentlyAddedPlayers", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetPlayerInvoicesDue(token) {
    return axios.get(API_ENDPOINT + "api/Invoices/GetPlayerInvoicesDue", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetAllNotes(token) {
    return axios.get(API_ENDPOINT + "api/Notes/GetAllNotes", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetPlayersExpiringContracts(token) {
    return axios.get(
      API_ENDPOINT + "api/Contracts/GetPlayersExpiringContracts",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  GetAllEventDates(token) {
    return axios.get(API_ENDPOINT + "api/Events/GetAllEventDates", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  GetActiveSigningBonuses(token) {
    return axios.get(
      API_ENDPOINT + "api/Contracts/GetActiveSigningBonuses",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  EPGetPlayersByName(token, firstName, lastName) {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetPlayersByName?firstName=${firstName}&lastName=${lastName}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  EPGetPlayersByNameStartsWith(token, firstName, lastName) {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetPlayersByNameStartsWith?firstName=${firstName}&lastName=${lastName}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  EPGetPlayerById(token, id) {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetPlayerById?id=${id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  EPGetPlayerGameLogs(token, playerId) {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetPlayerGameLogs?playerId=${playerId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  GetPlayerScoutingReports(token, activeOnly = true) {
    return axios.get(
      API_ENDPOINT + `api/ScoutingReports/GetPlayerScoutingReports?activeOnly=${activeOnly}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  CreateScoutingReport(token, request, activeOnly = true) {
    return axios.post(API_ENDPOINT + `api/ScoutingReports/CreateScoutingReport?activeOnly=${activeOnly}`, request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  UpdateScoutingReport(token, request) {
    return axios.post(API_ENDPOINT + `api/ScoutingReports/UpdateScoutingReport`, request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteScoutingReport(token, reportId, activeOnly = true) {
    return axios.delete(
      API_ENDPOINT + `api/ScoutingReports/DeleteScoutingReport?reportId=${reportId}&activeOnly=${activeOnly}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  GetPuckPediaDashboard(token) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaDashboard`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaDashboardByTeam(token, teamId) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaDashboardByTeam?teamId=${teamId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaTeams(token, request) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaTeams`, 
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaTeam(token, request) {
    return axios.post(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaTeamDetail`, request,  
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaInjuriesByTeam(token, teamId) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaInjuriesByTeam?teamId=${teamId}`, 
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaSigningsByTeam(token, teamId) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaSigningsByTeam?teamId=${teamId}`, 
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaTradesByTeam(token, teamId) {
    return axios.get(
      API_ENDPOINT + `api/PuckPedia/GetPuckPediaTradesByTeam?teamId=${teamId}`, 
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaDraftsByTeam(token, request, teamId) {
    return axios.post(
      API_ENDPOINT + `api/PuckPedia/GetDraftsByTeam?teamId=${teamId}`, request,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );  
  },
  GetPuckPediaPlayersByPlayerIds(token, request) {
    return axios.post(
      API_ENDPOINT + `api/PuckPedia/GetPlayersByPlayerIds`, request,  
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ); 
  },
  GetSearchResults(token, searchWord, type = 'player') {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetSearchResults?q=${searchWord}&type=${type}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  GetEPPlayerDetail(token, playerId) {
    return axios.get(
      API_ENDPOINT + `api/EliteProspects/GetPlayerDetail?playerId=${playerId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },
  UpsertNoContractPayout(token, request) {
    return axios.post(API_ENDPOINT + 'api/NoContractPayouts/UpsertNoContractPayout', request, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
  DeleteNoContractPayout(token, payoutId, playerId) {
    return axios.delete(API_ENDPOINT + `api/NoContractPayouts/DeleteNoContractPayoutById?payoutId=${payoutId}&playerId=${playerId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  },
};
